import React from "react";
import Layout from "@components/layout";

const ErrorPage = () => {
  return (
      <Layout PageTitle="Error 404 Page">
        <h1>Opps Nothing Found</h1>
      </Layout>
  );
};

export default ErrorPage;
